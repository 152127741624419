import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/behandlung-eines-reizdarms"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/*<li className="menu-331 active-trail">
                      <a href="/diagnose-reizdarm/" className="active-trail">
                        Info zu Reizdarm
                      </a>
    </li>*/}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div id="block-menu-menu-reizdarm" className="block block-menu">
                  <h2>Reizdarm</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/diagnose-reizdarm/" title>
                          Was ist Reizdarm (IBS)?
                        </a>
                      </li>
                      <li className="leaf active-trail">
                        <a
                          href="/behandlung-eines-reizdarms/"
                          title
                          className="active-trail active"
                        >
                          Behandlung eines Reizdarms
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/reizdarm-ernahrung/" title>
                          Ernährung bei Reizdarm
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Behandlung eines Reizdarms{" "}
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="headimg border">
                      <img
                        typeof="foaf:Image"
                        src="/sites/default/files/header_visuals_695x286_diagnose_reizdarm.jpg"
                        width="695"
                        height="286"
                      />{" "}
                    </div>{" "}
                    <div className="std-site">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span style={{ top: "27px" }}>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Behandlung und Therapie
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            Für viele Betroffene ist die Diagnose ‚Reizdarm‘
                            auch eine Erleichterung, da sich hinter den
                            Symptomen keine krankhafte Veränderung des Darms
                            versteckt und die Symptomatik durch eine Änderung
                            der Lebensweise und Ernährung zum Teil in den Griff
                            zu bekommen ist.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="left">
                          <div className="field field-name-field-sidebar-text field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <h3>Tipp:</h3>
                                <p>
                                  Da die Reizdarm-Symptome durch viele
                                  verschiedene Faktoren bedingt sein können, ist
                                  es wichtig, individuell die Ursachen zu
                                  klären. Am besten funktioniert das, wenn Sie
                                  ein Tagebuch führen, in dem Sie die
                                  Geschehnisse des Tages festhalten und
                                  notieren, was Sie essen und welche Symptome
                                  auftreten. In einigen Fällen ist nicht ein
                                  einzelner Faktor Auslöser, sondern eine
                                  Kombination aus Stress und Ernährung.
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="right">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <h3>
                                  Umstellung Ihrer Lebensweise und Ernährung
                                </h3>
                                <ul>
                                  <li>
                                    <strong>
                                      Reduzieren Sie Stress im Berufsalltag
                                    </strong>
                                    <br />
                                    Es muss ja nicht gleich eine neue berufliche
                                    Laufbahn sein. Pausen einzubauen, weniger
                                    Überstunden zu machen und feste Termine in
                                    der Woche festzulegen, in denen man sich
                                    Zeit für sich selbst nimmt – all das sind
                                    Massnahmen, die Sie in Ihrem Berufsalltag
                                    ergreifen können, um den{" "}
                                    <a href="/hohe-arbeitsbelastung/">
                                      Stress{" "}
                                    </a>
                                    zu reduzieren.
                                  </li>
                                  <li>
                                    <p>
                                      <strong>Treiben Sie Sport</strong>
                                      <br />
                                      Sportliche Aktivitäten wie Joggen,
                                      Schwimmen oder Radfahren haben einen
                                      tollen Doppeleffekt. Sie fördern die
                                      Verdauung und bauen Stress ab.
                                    </p>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <p>
                                      <strong>
                                        Ernähren Sie sich ausgewogen und
                                        ballaststoffreich
                                      </strong>
                                      <br />
                                      <a href="/reizdarm-ernahrung/">
                                        Unbedingt meiden sollten Sie fettige,
                                        sehr würzige Gerichte oder Speisen
                                      </a>
                                      , die Nahrungsmittel enthalten, die bei
                                      Ihnen die Reizdarm-Symptome auslösen.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>
                                        Nehmen Sie kleine Mahlzeiten zu sich und
                                        essen Sie dafür lieber öfter
                                      </strong>
                                      <br />
                                      Ihr Verdauungstrakt wird durch grosse
                                      Mahlzeiten viel mehr belastet.
                                    </p>
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                                <h3>Medikamentöse Behandlungen von Reizdarm</h3>
                                <ul>
                                  <li>
                                    Lassen Sie sich von Ihrem Arzt oder
                                    Apotheker beraten, um die für Sie am besten
                                    geeignete Behandlung zu finden.
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                                <h3>Alternative Therapien</h3>
                                <ul>
                                  <li>
                                    <p>
                                      <strong>
                                        Meditation oder Yoga können Ihnen helfen
                                      </strong>
                                      <br />
                                      Meditation und Yoga gelten als
                                      stressabbauende und das Wohlbefinden
                                      fördernde Techniken. Daher können Sie auch
                                      bei Reizdarm helfen.
                                    </p>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <p>
                                      <strong>
                                        Suchen Sie einen Experten auf
                                      </strong>
                                      <br />
                                      Hypnotherapie und Psychotherapie haben
                                      einigen Betroffenen schon geholfen. Auch
                                      ein Beratungsgespräch zu Stressmanagement
                                      können Sie wahrnehmen, um die Reizdarm-
                                      Symptome in den Griff zu bekommen.
                                    </p>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <p>
                                      <strong>Pflanzliche Mittel</strong>
                                      <br />
                                      Einigen pflanzlichen Präparaten wird die
                                      Unterstützung der Darmfunktion nachgesagt.
                                      Ein Arzt für Naturheilkunde, oder ein
                                      zugelassener Homöopath kann Sie beraten,
                                      wenn Sie pflanzliche Alternativen
                                      versuchen möchten.
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
